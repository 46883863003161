import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const BreadCrumb = ({ infos }) => {
  const {
    postType, //may be one of: index, post, archive, category, or anything else
    title,
    category,
    path,
    parent
  } = infos;
  const props = useStaticQuery(query);
  const archive = props.wpPage;
  let crumbs = [];
  crumbs.push({ tag: "a", uri: "/", title: "Accueil" });
  switch (postType) {
  case "archive":
    crumbs.push({ tag: "span", uri: archive.uri, title: archive.title });
    break;
  case "category":
    crumbs.push({ tag: "a", uri: archive.uri, title: archive.title });
    crumbs.push({ tag: "span", uri: path, title });
    break;
  case "post":
    crumbs.push({ tag: "a", uri: archive.uri, title: archive.title });
    crumbs.push({ tag: "a", uri: category.uri, title: category.name });
    crumbs.push({ tag: "span", uri: path, title });
    break;
  default:
    if ((typeof parent !== "undefined" && parent.title && parent.url )) {
      crumbs.push({ tag: "a", uri: parent.url, title: parent.title });
    }
    crumbs.push({ tag: "span", uri: path, title });
  }
  if (postType === "index") {
    return (<></>);
  }
  return (
    <div id="breadcrumbs">
      <ol role="navigation" aria-label="Vous êtes ici : " itemScope="" itemType="https://schema.org/BreadcrumbList">
        { crumbs.map((e,i) => {
          return (
            <li itemProp="itemListElement" itemScope="" itemType="https://schema.org/ListItem" key={i}>
              { (e.tag === "a")
                ? (<Link
                  itemScope=""
                  itemType="https://schema.org/Thing"
                  itemProp="item"
                  to={ e.uri }
                  itemID={ e.uri }>
                  <span itemProp="name">
                    { e.title }
                  </span>
                </Link>)
                : (<span
                  itemScope=""
                  itemType="https://schema.org/Thing"
                  itemProp="item"
                  itemID={ e.uri }>
                  <span itemProp="name">
                    { e.title }
                  </span>
                </span>
                )
              }
              <meta itemProp="position" content={i+1} />
            </li>
          );
        })
        }
      </ol>
    </div>
  );
};

export const query = graphql`
  query BreadCrumb {
    wpPage(isPostsPage: {eq: true}) {
      title
      uri
    }
  }
`;

export default BreadCrumb;
